import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

const AuthFormContainer = ({ children, error, message }) => {
  const classes = useStyles();
  return (
    <Box
      alignItems='center'
      bgcolor='background.default'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      width='50vw'
    >
      {children}
      {message && (
        <Box mt={2}>
          <Typography
            className={clsx(classes.message, error && classes.errStyle)}
            variant='subtitle1'
          >
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AuthFormContainer;
