import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  message: {
    margin: '1rem auto',
    color: 'green',
  },
  errStyle: {
    color: 'red',
  },
});

export { useStyles };
