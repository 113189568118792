import firebase from 'firebase/app';
import { NextRouter } from 'next/router';

import { COLLECTIONS } from '../src/constants';
import { auth, firestore, googleProvider } from '../src/firebaseApp';
import type { GoogleUserCredential, ModifyPermissionsReq } from '../src/types';

/**
 *  Initializes a user document when a user is first created
 *
 * @param user object containing a uid, name and email
 * @returns Promise<void>
 */
const createNewUserDoc = async (
  user: { uid: string; email: string },
  name: string = ''
): Promise<void> => {
  const { uid, email } = user;

  // create the user document in firestore
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .set({
      activitiesCompleted: {},
      activityId: '',
      courseId: '',
      courseIds: [],
      email: email,
      name,
      lessonId: '',
      lessonsCompleted: {},
      moduleId: '',
      modulesCompleted: {},
      trackId: '',
      tracksCompleted: {},
      onboardingComplete: true,
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

/**
 * Makes request to create-user api route, which uses admin sdk
 * to create a user in Firebase Authentication
 *
 * @param data object containing a students email, name, id
 * @returns
 */
const createUser = async (data) => {
  const response = await fetch('/api/create-user', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  const { error, message, userRecord } = await response.json();
  return { error, message, userRecord };
};

/**
 *  Initializes a user document when a user is first created
 *
 * @param user object containing a uid, name and email
 * @returns Promise<void>
 */
const createUserDoc = async (
  user: {
    uid: string;
    name: string;
    email: string;
  },
  DEFAULTS
): Promise<void> => {
  // get user info
  const uid = user.uid;
  const name = user.email.split('@')[0];
  const email = user.email;

  // user defaults for webdev prework
  const courseId = DEFAULTS.COURSE_ID;
  const trackId = DEFAULTS.TRACK_ID;
  const lessonId = DEFAULTS.LESSON_ID;
  const moduleId = DEFAULTS.MODULE_ID;
  const activityId = DEFAULTS.ACTIVITY_ID;

  // create the user document in firestore
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .set({
      activitiesCompleted: {},
      activityId,
      courseId,
      courseIds: [courseId],
      email: email,
      name: name,
      lessonId,
      lessonsCompleted: {
        [courseId]: {},
      },
      moduleId,
      modulesCompleted: {},
      trackId,
      tracksCompleted: {},
      onboardingComplete: false,
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const createContactInHubspot = async (
  firstname: string,
  lastname: string,
  email: string
): Promise<void> => {
  const res = await fetch('/api/hubspot/contacts', {
    method: 'POST',
    body: JSON.stringify({
      firstname,
      lastname,
      email,
    }),
  });
  const { result, error } = await res.json();
};

/**
 * Logs the current user out of the application
 * @param router NextRouter instance to handle internal navigation
 */
const logOut = (router: NextRouter) => {
  auth
    .signOut()
    .then(() => {
      router.push('/signin');
    })
    .catch((error) => {
      console.log('Error signing out:', error);
    });
};

/**
 * Makes a call to the make-public-user api route and
 * gives the user with the given email a public user custom claim
 *
 * @param email Email of the user to give public user custom claim
 */
const makePublicUser = async (email: string): Promise<void> => {
  console.log({ email });
  try {
    await fetch('/api/make-public-user', {
      method: 'PUT',
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

const sendResetEmail = async (
  email: string,
  setMessage: (message: string) => void
): Promise<void> => {
  try {
    await auth.sendPasswordResetEmail(email);
    setMessage(`Successfully sent password reset email to ${email}.`);
  } catch (error) {
    console.error(error);
    setMessage(
      'Failed to send password reset email. Try again later or contact admin.'
    );
  }
};

const sendSignUpEmail = async (email: string) => {
  const origin = window ? window.location.origin : process.env.CLIENT_URL;

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: origin,
    // This must be true.
    handleCodeInApp: true,
  };

  return auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      return 'Email sent';
    })
    .catch((error) => {
      console.error('error sending mail', error);
      throw error;
    });
};

const sendVerificationEmail = async (user) => {
  const origin = window ? window.location.origin : process.env.CLIENT_URL;

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${origin}/dashboard`,
    // This must be true.
    handleCodeInApp: true,
  };
  return await user.sendEmailVerification(actionCodeSettings);
};

const setUserAsMember = async (userEmail: string, idToken: string) => {
  const data: ModifyPermissionsReq = {
    userEmail,
    idToken,
    roles: { member: true },
  };
  try {
    const response = await fetch('/api/setUserPermissions', {
      method: 'PUT',
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

const signInUser = async (email: string, password: string): Promise<any> => {
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const userCred = await auth.signInWithEmailAndPassword(email, password);
    return userCred.user;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Google authentication when user logs in using google
const signInWithGoogle = async () => {
  const provider = googleProvider;
  provider.addScope('profile');
  provider.addScope('email');
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const userCred: GoogleUserCredential = await auth.signInWithPopup(provider);
    const { user, additionalUserInfo } = userCred;
    // if new user, create db entry
    if (additionalUserInfo?.isNewUser) {
      await createNewUserDoc(user, user.displayName);
    }
    const firstname = additionalUserInfo.profile.given_name;
    const lastname = additionalUserInfo.profile.family_name;
    return { user, firstname, lastname };
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

const signUpUser = async (email: string, password: string, name: string) => {
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const userCred = await auth.createUserWithEmailAndPassword(email, password);
    const { user } = userCred;
    await sendVerificationEmail(user);
    await user.updateProfile({
      displayName: name,
    });
    await createNewUserDoc(user, name);
    return user;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export {
  createNewUserDoc,
  createUser,
  createUserDoc,
  createContactInHubspot,
  logOut,
  makePublicUser,
  sendResetEmail,
  sendSignUpEmail,
  sendVerificationEmail,
  setUserAsMember,
  signInUser,
  signInWithGoogle,
  signUpUser,
};
